<template>
  <b-card title="Data Rekap Jawaban 📃">
    <b-row>
      <b-col>
        <b-table
          stacked
          outlined
          class="text-left"
          :items="results"
          :fields="kolom"
        >
      </b-table>
      </b-col>
    </b-row>
    <b-row>
    <b-col
      md="2"
      sm="2"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="6"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(soal)="data">
          <div class="row">
            <span class="col-12 mb-1" v-html="filterLatex(data.item.soal)" />
            <b-img class="col-12 mb-1" style="min-width: 120px; height: auto;" :src="data.item.file_soal" v-if="data.item.file_soal" fluid alt="Tidak ada gambar"/>
            <div class="col-12 column">
              <div class="row" style="margin-left: 0px;">
                <span class="font-weight-bold">A. &nbsp;</span>
                <span class="media-heading" v-html="filterLatex(data.item.pil_a)" />
              </div>
              <b-img style="max-width: 150px; height: auto;" :src="data.item.file_a" v-if="data.item.file_a" fluid alt="Tidak ada gambar"/>
            </div>
            <div class="col-12 column">
              <div class="row" style="margin-left: 0px;">
                <span class="font-weight-bold">B. &nbsp;</span>
                <span class="media-heading" v-html="filterLatex(data.item.pil_b)" />
              </div>
              <b-img style="max-width: 150px; height: auto;" :src="data.item.file_b" v-if="data.item.file_b" fluid alt="Tidak ada gambar"/>
            </div>
            <div class="col-12 column">
              <div class="row" style="margin-left: 0px;">
                <span class="font-weight-bold">C. &nbsp;</span>
                <span class="media-heading" v-html="filterLatex(data.item.pil_c)" />
              </div>
              <b-img style="max-width: 150px; height: auto;" :src="data.item.file_c" v-if="data.item.file_c" fluid alt="Tidak ada gambar"/>
            </div>
            <div class="col-12 column">
              <div class="row" style="margin-left: 0px;">
                <span class="font-weight-bold">D. &nbsp;</span>
                <span class="media-heading" v-html="filterLatex(data.item.pil_d)" />
              </div>
              <b-img style="max-width: 150px; height: auto;" :src="data.item.file_d" v-if="data.item.file_d" fluid alt="Tidak ada gambar"/>
            </div>
            <div class="col-12 column">
              <div class="row" style="margin-left: 0px;">
                <span class="font-weight-bold">E. &nbsp;</span>
                <span class="media-heading" v-html="filterLatex(data.item.pil_e)" />
              </div>
              <b-img style="max-width: 150px; height: auto;" :src="data.item.file_e" v-if="data.item.file_e" fluid alt="Tidak ada gambar"/>
            </div>
          </div>
        </template>
        <template #cell(pembahasan)="data">
          <span class="col-12 mb-1" v-html="filterLatex(data.item.pembahasan)" />
          <b-img style="min-width: 120px; height: auto;" :src="data.item.file_pembahasan" v-if="data.item.file_pembahasan" fluid alt="Tidak ada gambar"/>
        </template>
        <template #cell(status)="data">
            <span class="font-weight-bold" v-if="data.item.jawaban === data.item.kunci_jawaban">
              <b-badge pill variant="success">Benar</b-badge>
            </span>
            <span class="font-weight-bold" v-else-if="data.item.jawaban == null">
              <b-badge pill variant="warning">Kosong</b-badge>
            </span>
            <span class="font-weight-bold" v-else>
              <b-badge pill variant="danger">Salah</b-badge>
            </span>
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BImg, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import katex from 'katex'
import 'katex/dist/katex.min.css'

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id', label: 'No', tdClass: 'align-td', centered: true,
        },
        { key: 'soal', label: 'Soal', tdClass: 'soal-td' },
        { key: 'jawaban', label: 'Jawab', tdClass: 'align-td' },
        { key: 'kunci_jawaban', label: 'Kunci Jawaban', tdClass: 'align-td' },
        {
          key: 'pembahasan', label: 'pembahasan', tdClass: 'pembahasan-td',
        },
        { key: 'status', label: 'Status', tdClass: 'align-td' },
      ],
      items: [
      ],
      kolom: [
        {
          key: 'siswa.nama',
          label: 'Nama :',
          tbodyTrClass: 'align-label',
          trClass: 'text-left',
        },
        { key: 'ujian.nama_ujian', label: 'Nama Ujian :' },
        { key: 'nilai', label: 'Nilai :' },
      ],
      results: [
      ],
      status: [{
        1: 'Benar', 0: 'Salah',
      },
      {
        1: 'light-success', 0: 'light-danger',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  filters: {
    strippedContent(string) {
      return string.replace(/<\/?[^>]+>/ig, ' ').substring(0, 30)
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getData()
    this.renderLatex()
  },
  created() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getData()
  },
  methods: {
    filterLatex(text) {
      const regex = /\*mtk(.*?)mtk\*/
      const match = text.match(regex)
      if (match && match.length > 1) {
        const latexText = match[1].trim()
        const renderedLatexText = this.renderLatex(latexText)
        return text.replace(/\*mtk(.*?)mtk\*/, `<span style="color: blue;"></span>${renderedLatexText}`)
      }
      return text.replace(/\[(.*?)\]/g, '<span style="color: blue;">$1</span>')
    },
    escapeLatexCharacters(latexString) {
      return latexString
        .replace(/\\/g, '\\\\')
        .replace(/\$/g, '\\$')
        .replace(/#/g, '\\#')
        .replace(/_/g, '\\_')
        .replace(/\^/g, '\\^{}')
        .replace(/{/g, '\\{')
        .replace(/}/g, '\\}')
    },
    renderLatex(latexString) {
      try {
        return katex.renderToString(latexString)
      } catch (error) {
        return 'Error: Invalid LaTeX syntax (Rumus tidak terbaca, Harap gunakan foto )'
      }
    },
    getData() {
      this.$http.get(`/ujian/result/${this.$route.params.idUjian}/${this.$route.params.idSiswa}/${this.$route.params.id}`).then(response => {
        if (response.data.status) {
          this.items = response.data.data
          this.totalRows = response.data.data.length
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
      this.$http.get(`/result/${this.$route.params.id}`).then(response => {
        if (response.data.status) {
          this.results = response.data.data
        }
      })
    },
    deleteData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/ujian/soal/${id}`).then(response => {
            if (response.data.status) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getData()
            } else {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Membatalkan untuk menghapus data!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}

</script>

<style>
.soal-td{
  min-width: 400px;
}
.pembahasan-td{
  min-width: 400px;
  vertical-align: baseline !important;
}
.align-td{
  text-transform: capitalize;
  vertical-align: baseline !important;
}
.align-label{
  text-align: left;
}
</style>
